import React, { useEffect, useState } from "react";
import "simplebar-react/dist/simplebar.min.css";
import i18next from "i18next";
import back from "../../assets/back.png";
import { useNavigate, useNavigation } from "react-router-dom";
import { jobDataRef } from "../../components/CareerComp";
import { handleApplyButton } from "../../config/localStorage";
import i18n from "../../i18n";
import { HeaderComp } from "../../components/HeaderComp";

export const DetailPage = () => {
  const location = useNavigate();
  const [data] = useState(jobDataRef.current || {});

  function handleBackButton() {
    location("/career");
  }

  const [language, setlanguage] = useState(i18n.language);
  i18n.on("languageChanged", (lng) => {
    setlanguage(lng);
  });
  return (
    <>
      <HeaderComp
        changeLanguage={(lng) => i18n.changeLanguage(lng)}
        language={language}
      />
      <div className="w-screen h-screen overflow-x-hidden  flex justify-center">
        <div className="mt-[70px] bg-white p-10 w-[90%] max-w-[1300px] ">
          <p className="text-[30px] pb-[2rem] text-center font-Nunito leading-7 text-black tracking-wide">
            {i18next.t("Job")}{" "}
            <span className="font-bold">{i18next.t("Open")}</span>
          </p>
          <div className="flex justify-between border-b-[2px] pb-4">
            <div>
              <p className="text-[30px] font-bold font-Nunito leading-7 text-black">
                {data?.job_position || ""}
              </p>
              <p className=" font-Nunito font-semibold  leading-7 text-[#808080] tracking-wide">
                {data?.job_location?.name || ""}, Indonesia |{" "}
                {data?.department?.name || ""} | {data?.job_type?.name || ""} |{" "}
                {data?.job_location_type?.name || ""}{" "}
              </p>
            </div>
            <div className="bg-[#40AE49] cursor-pointer rounded-lg p-1 w-[7%] h-[6vh] flex justify-center items-center"
              onClick={() => handleApplyButton()}>
              <p className="text-center text-[20px] text-white font-Nunito leading-7 text-black ">
                {i18next.t("Apply")}
              </p>
            </div>
          </div>
          <p className="whitespace-pre-line pt-5 text-lg ml-2 font-Nunito font-semibold  leading-7 text-[#808080] tracking-wide">
            {data?.job_desc}
          </p>
          <p className="text-[30px] font-bold pt-[3%] font-Nunito leading-7 text-black">Key Responsibilities</p>

          <p className="whitespace-pre-line pt-3 ml-2 text-lg font-Nunito font-semibold  leading-7 text-[#808080] tracking-wide">
            {data?.job_key_responsibilities}
          </p>
          <p className="text-[30px] font-bold pt-[3%] font-Nunito leading-7 text-black ">Qualifications</p>
          <p className="whitespace-pre-line pt-3 ml-2 text-lg font-Nunito font-semibold leading-7 text-[#808080] tracking-wide">
            {data?.job_qualifications}
          </p>
          {/* <div className="flex justify-center">
        <div className="bg-[#40AE49] cursor-pointer rounded-lg p-1 w-[7%] h-[6vh] flex justify-center items-center"
          onClick={() => handleApplyButton()}>
          <p className="text-center text-[20px] text-white font-Nunito leading-7 text-black ">
            {i18next.t("Apply")}
          </p>
        </div>
      </div> */}
          <div
            className="border-b border-[#40AE49] cursor-pointer pt-10 flex items-center w-[17%] justify-center"
            onClick={() => handleBackButton()}
          >
            <img src={back} className="h-[15%] w-[15%]" />
            <p className=" text-[16px] pl-3  font-Nunito leading-7 text-[#808080] tracking-wide">
              {/* {i18next.t("Apply")} */}
              {i18next.t("AllOpenings")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
