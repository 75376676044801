import React, { useRef, useState } from "react";
import { HeaderComp } from "../../components/HeaderComp";
import { CarouselComp } from "../../components/CarouselComp";
import { EcoSystemComp } from "../../components/EcoSystemComp";
import { ServicesComp } from "../../components/ServicesComp";
import { BeleafForPeopleComp } from "../../components/BeleafForPeopleComp";
import { SalaedPartnersComp } from "../../components/SalaedPartnersComp";
import { FooterComp } from "../../components/FooterComp";
import i18n from "../../i18n";
import DocumentMeta from "react-document-meta";

export const HomePage = () => {
  const ecoRef = useRef(null);
  const [language, setlanguage] = useState(i18n.language);
  i18n.on("languageChanged", (lng) => {
    setlanguage(lng);
  });
  const meta = {
    title: 'Home',
    description: 'Experience the farm-to-table journey with Beleaf Farm. From seed to table, we deliver fresh, wholesome produce while supporting local farmers and sustainable practices.',
    canonical: 'http://beleaf.co.id',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  }
  return (
    <DocumentMeta {...meta}>

      <div className="w-screen h-screen overflow-x-hidden">

        <HeaderComp
          changeLanguage={(lng) => i18n.changeLanguage(lng)}
          language={language}
        />
        <CarouselComp
          onClick={() => ecoRef.current.scrollIntoView({ behavior: "smooth" })}
        />
        <EcoSystemComp ecoRef={ecoRef} />
        <ServicesComp />
        <BeleafForPeopleComp />
        <SalaedPartnersComp />
        <FooterComp />
      </div>
    </DocumentMeta>

  );
};
