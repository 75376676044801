import { BrowserRouter, Route, Routes } from "react-router-dom";
import { localStorageNavOptions } from "./config/localStorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {localStorageNavOptions.map((item) => {
            return (
              <Route key={item.id} path={item.path} element={item.element} />
            );
          })}
        </Routes>
        <ToastContainer autoClose={3000} />
      </BrowserRouter>
    </div>
  );
}

export default App;
