import React, { useState } from "react";
import { HeaderComp } from "../../components/HeaderComp";
import { ProductsTopLineComp } from "../../components/ProductsTopLineComp";
import { ProductsComp } from "../../components/ProductsComp";
import { FooterComp } from "../../components/FooterComp";
import i18n from "../../i18n";
import DocumentMeta from "react-document-meta";

export const ProductPage = () => {
  const [language, setlanguage] = useState(i18n.language);
  i18n.on("languageChanged", (lng) => {
    setlanguage(lng);
  });
  const meta = {
    title: 'Product',
    description: 'Discover a world of fresh, wholesome produce with Beleaf. Enjoy a diverse range of leafy greens, root vegetables, tropical fruits, tomatoes, and salads.',
    canonical: 'http://beleaf.co.id/products',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  }
  return (
    <DocumentMeta {...meta}>

      <div className="w-screen h-screen overflow-x-hidden">
        <HeaderComp
          changeLanguage={(lng) => i18n.changeLanguage(lng)}
          language={language}
        />
        <ProductsTopLineComp />
        <ProductsComp />
        <FooterComp />
      </div>
    </DocumentMeta>

  );
};
