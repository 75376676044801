import logoImage from "../assets/beleaf_logo.png";
import { BsFillCaretDownFill } from "react-icons/bs";
import { VscThreeBars } from "react-icons/vsc";
import { RxCross1 } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";
import { localStorageNavOptions } from "../config/localStorage";
import i18next from "i18next";
import i18n from "../i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showContactUsModal } from "../config/store/reducers/appReducers";

export const HeaderComp = ({ changeLanguage, language }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [showNav, setShowNav] = useState(false);


  return (
    <div className="py-5 z-20 fixed w-full bg-white shadow-md">
      <div className="flex items-center px-5">
        <div className="w-1/2">
          <img className="h-[40px]" src={logoImage} alt="..." />
        </div>
        <div className="w-1/2 hidden lg:flex items-center justify-end">
          <div className="flex">
            {localStorageNavOptions.map((item) => {
              if (item?.id === 6) {
                return <></>
              }
              return (
                <div
                  className={`mr-10 font-CircularStd text-base  cursor-pointer tracking-wider`}
                  key={item.id}
                >
                  <Link to={item.path}>
                    <p
                      className={`${
                        location.pathname === item.path
                          ? "text-[#40AE49] font-Nunito font-extrabold text-base"
                          : "text-black font-semibold font-Nunito text-base  tracking-wider"
                      } hover:text-[#40AE49]`}
                    >
                      {i18next.t(item.name)}
                    </p>
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="flex ml-8 items-center ">
            <p
              onClick={() => dispatch(showContactUsModal(true))}
              className="bg-[#40AE49] text-white text-base lg:text-xs w-[9rem]  text-center  font-Nunito font-bold tracking-wider  px-[0.3rem]  py-[0.7rem] rounded-md cursor-pointer hover:bg-[#F491A3]"
            >
              {i18next.t("BecomeOurMitra")}
            </p>
          </div>
          <div className="h-[40px] bg-black w-[1.5px] mx-5" />
          <div className="flex items-center">
            <p className="text-sm text-[#40AE49] mr-2">
              {i18n.language.toString().toUpperCase()}
            </p>
            <BsFillCaretDownFill
              onClick={() => {
                if (i18n.language === "en") {
                  changeLanguage("id");
                } else {
                  changeLanguage("en");
                }
              }}
              size={15}
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="w-1/2 flex visible lg:hidden items-center justify-end">
          <div
            onClick={() => setShowNav(!showNav)}
            className="bg-black py-2 px-4 rounded-md text-white"
          >
            {showNav ? <RxCross1 /> : <VscThreeBars />}
          </div>
        </div>
      </div>
      {showNav ? (
        <div className="visible lg:hidden mt-5">
          {localStorageNavOptions.map((item, _i) => {
            return (
              <div
                className={`font-CircularStd cursor-pointer py-3 px-3 ${
                  _i < localStorageNavOptions.length - 1
                    ? "border-b-gray-300 border-b-2"
                    : ""
                } ${
                  location.pathname === item.path
                    ? "text-[#40AE49]"
                    : "text-black"
                }`}
                key={item.id}
              >
                <Link to={item.path}>
                  <p>{i18next.t(item.name)}</p>
                </Link>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
