import { Carousel, initTE } from "tw-elements";
import homeImg from "../assets/homeImg.jpg";
import carousel02 from "../assets/carousel02.jpg";
import carousel03 from "../assets/carousel03.jpg";
import { useEffect } from "react";
import i18next from "i18next";

export const CarouselComp = ({ onClick }) => {
  useEffect(() => {
    initTE({ Carousel });
  }, []);
  return (
    <div
      id="carouselExampleCaptions"
      class="relative"
      data-te-carousel-init
      data-te-ride="carousel"
      className="w-screen relative"
    >
      {/* <div
        class="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0"
        data-te-carousel-indicators
      >
        <button
          type="button"
          data-te-target="#carouselExampleCaptions"
          data-te-slide-to="0"
          data-te-carousel-active
          class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-te-target="#carouselExampleCaptions"
          data-te-slide-to="1"
          class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-te-target="#carouselExampleCaptions"
          data-te-slide-to="2"
          class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
          aria-label="Slide 3"
        ></button>
      </div> */}

      <div class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
        <div
          class="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
          data-te-carousel-active
          data-te-carousel-item
        //style="backface-visibility: hidden"
        >
          <img
            src={homeImg}
            class="object-cover w-full h-screen"
            alt="..."
          />
          <div class="absolute flex justify-center items-center backdrop-brightness-75 h-full left-[0%] transform right-[0%] top-[0%] text-center text-white md:block">
            <div className="h-full flex justify-center items-center">
              <div>
                <p class="font-Nunito font-thin text-6xl">{i18next.t("ItIsOurNature")}</p>
                <p class="font-Nunito font-semibold text-3xl lg:text-6xl ">
                  {i18next.t("toNurture")}
                </p>
                <p className="font-Nunito font-medium text-lg mt-10 px-10">
                  {i18next.t("ElevatingFarms")}
                </p>
                <div className="flex justify-center mt-10">
                  <p
                    onClick={() => onClick()}
                    className="bg-[#F491A3] font-Nunito text-sm py-3 px-5 rounded-md cursor-pointer hover:bg-[#3EB049]"
                  >
                    {i18next.t("GrowTheFuture")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
          data-te-carousel-item
        //style="backface-visibility: hidden"
        >
          <img
            src={carousel02}
            class="object-cover w-full h-screen"
            alt="..."
          />
          <div class="absolute flex justify-center items-center backdrop-brightness-75 h-full left-[0%] transform right-[0%] top-[0%] text-center text-white md:block">
            <div className="h-full flex justify-center items-center">
              <div>
                <p class="font-Nunito font-thin text-3xl lg:text-7xl">{i18next.t("ItIsOurNature")}</p>
                <p class="font-Nunito font-semibold text-3xl lg:text-6xl ">
                  {i18next.t("toNurture")}
                </p>
                <p className="font-Nunito font-medium mt-10 px-10">
                  {i18next.t("ElevatingFarms")}
                </p>
                <div className="flex justify-center mt-10">
                  <p
                    onClick={() => onClick()}
                    className="bg-[#F491A3] font-Nunito text-sm py-3 px-5 rounded-md cursor-pointer hover:bg-[#3EB049]"
                  >
                    {i18next.t("GrowTheFuture")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* <div
          class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
          data-te-carousel-item
          //style="backface-visibility: hidden"
        >
          <img
            src={carousel02}
            class="object-cover w-full h-[700px]"
            alt="..."
          />
          <div class="absolute flex justify-center items-center backdrop-brightness-75 h-full left-[0%] transform right-[0%] top-[0%] text-center text-white md:block">
            <div className="h-full flex justify-center items-center">
              <div>
                <p class="text-3xl lg:text-7xl">{i18next.t("ItIsOurNature")}</p>
                <p class="text-3xl lg:text-6xl font-bold">
                  {i18next.t("toNurture")}
                </p>
                <p className="font-bold mt-10 px-10">
                  {i18next.t("ElevatingFarms")}
                </p>
                <div className="flex justify-center mt-10">
                  <p
                    onClick={() => onClick()}
                    className="bg-[#F491A3] text-sm py-3 px-5 rounded-sm cursor-pointer hover:bg-[#3EB049]"
                  >
                    {i18next.t("GrowTheFuture")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}



        {/* <div
          class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
          data-te-carousel-item
          //style="backface-visibility: hidden"
        >
          <img
            src={carousel02}
            class="object-cover w-full h-[700px]"
            alt="..."
          />
          <div class="absolute flex justify-center items-center backdrop-brightness-75 h-full left-[0%] transform right-[0%] top-[0%] text-center text-white md:block">
            <div className="h-full flex justify-center items-center">
              <div>
                <p class="text-3xl lg:text-7xl">{i18next.t("ItIsOurNature")}</p>
                <p class="text-3xl lg:text-6xl font-bold">
                  {i18next.t("toNurture")}
                </p>
                <p className="font-bold mt-10 px-10">
                  {i18next.t("ElevatingFarms")}
                </p>
                <div className="flex justify-center mt-10">
                  <p
                    onClick={() => onClick()}
                    className="bg-[#F491A3] text-sm py-3 px-5 rounded-sm cursor-pointer hover:bg-[#3EB049]"
                  >
                    {i18next.t("GrowTheFuture")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}




        <div
          class="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
          data-te-carousel-item
        //style="backface-visibility: hidden"
        >
          <img
            src={carousel03}
            class="object-cover w-full h-screen"
            alt="..."
          />
          <div class="absolute flex justify-center items-center backdrop-brightness-75 h-full left-[0%] transform right-[0%] top-[0%] text-center text-white md:block">
            <div className="h-full flex justify-center items-center">
              <div>
                <p class=" font-Nunito font-thin text-xl lg:text-6xl">{i18next.t("ItIsOurNature")}</p>
                <p class="font-Nunito font-semibold text-3xl lg:text-6xl ">
                  {i18next.t("toNurture")}
                </p>
                <p className="font-Nunito mt-10 px-10">
                  {i18next.t("ElevatingFarms")}
                </p>
                <div className="flex justify-center mt-10">
                  <p
                    onClick={() => onClick()}
                    className="bg-[#F491A3] font-body text-sm py-3 px-5 rounded-sm cursor-pointer hover:bg-[#3EB049]"
                  >
                    {i18next.t("GrowTheFuture")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        class="absolute top-0 bottom-0 left-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
        type="button"
        data-te-target="#carouselExampleCaptions"
        data-te-slide="prev"
      >
        <span class="inline-block h-8 w-16">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="h-8 w-12"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </span>
        <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Previous
        </span>
      </button>
      <button
        class="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
        type="button"
        data-te-target="#carouselExampleCaptions"
        data-te-slide="next"
      >
        <span class="inline-block h-8 w-16">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="h-8 w-12"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Next
        </span>
      </button>
    </div>
  );
};
